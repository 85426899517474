<template>
    <div>
        <Modal
        v-show="isModalVisible"
        @evenement="evenement"
        @close="closeModal"
        @edit="editRepair"
        :type="flux.level2"
        :constat="flux.constat"
        />
        <v-row>
            <v-col cols="12">
                <h2 class="mt10 pt10">TROU / ACCROC / DÉCHIRURE</h2>
                <div class="spacer"></div>
                <div>
                  <b>Choisissez le type de réparation que vous souhaitez</b>
                </div>
                <div class="spacer"></div>
                <v-tabs  
                    @change="onChangeTab"
                    next-icon="mdi-arrow-right-bold-box-outline"
                    prev-icon="mdi-arrow-left-bold-box-outline"
                    show-arrows
                    v-model="active_tab"
                >
                    <v-tab href="#reparation_simple_jean">STOPPAGE      
                        
                    </v-tab>
                    <v-tab href="#empiecement">EMPIÈCEMENT
                        <!--<v-btn @click="showModal('empiecements')">
                            <v-icon dark>
                                mdi-information
                            </v-icon>
                        </v-btn>-->
                    </v-tab>
                    <v-tab href="#patch">PATCH
                        <!-- <v-btn @click="showModal('patch')">
                            <v-icon dark>
                                mdi-information
                            </v-icon>
                        </v-btn>                         -->
                    </v-tab>
                    <v-tab href="#genouillere">GENOULLIÈRES
                        <!-- <v-btn @click="showModal('coudieres')">
                            <v-icon dark>
                                mdi-information
                            </v-icon>
                        </v-btn> -->
                    </v-tab>

                    <v-tab-item id="reparation_simple_jean"> 
                        <KeepAlive> 
                            <component v-bind:is="flux.activeTab" @showmodal="showModal" @evenement="evenement"></component>
                        </KeepAlive>
                        <!-- <Reparationsimple @showmodal="showModal" @evenement="evenement"/> -->
                    </v-tab-item>
                    <v-tab-item id="empiecement">
                        <!-- <Empiecement @showmodal="showModal" @evenement="evenement"/> -->
                        <KeepAlive> 
                            <component v-bind:is="flux.activeTab" @showmodal="showModal" @evenement="evenement"></component>
                        </KeepAlive> 
                    </v-tab-item>
                    <v-tab-item id="patch">
                        <!-- <Patch @showmodal="showModal" @evenement="evenement"/> -->
                        <KeepAlive> 
                            <component v-bind:is="flux.activeTab" @showmodal="showModal" @evenement="evenement"></component>
                        </KeepAlive> 
                    </v-tab-item>
                    <v-tab-item id="genouillere">
                        <!-- <Coudiere @showmodal="showModal" @evenement="evenement"/> -->
                        <KeepAlive>
                            <component v-bind:is="flux.activeTab" @showmodal="showModal" @evenement="evenement"></component>
                        </KeepAlive>
                    </v-tab-item>
                </v-tabs>
            </v-col>
        </v-row>
        <span style="display:none">{{ flux.actionId }}</span>
    </div>
</template>
<script>
//import axios from '@/axios.js'
import { mapState, mapMutations } from 'vuex'

// import Reparationsimple from '../reparations/reparation_simple.vue'
// import Empiecement from '../reparations/empiecement.vue'
// import Patch from '../reparations/patch.vue'
// import Coudiere from '../reparations/coudiere.vue'
import Modal from '../Modal.vue';


export default {
  name: 'Calculateur',
  data() {
    return {
      tabName: null,
      isModalVisible: false,
      //active_tabT:null,
    }
  },
  created () {
  },
  updated () {
  },
  computed: {
    ...mapState('cart-app', ['cards', 'products']),
    ...mapState('flux-app', ['flux']),
    active_tab : {
      // getter
      get() {
        return this.flux.activeTab
      },
      // setter
      set(newValue) {
        // Note: we are using destructuring assignment syntax here.
        this.setActiveTab(newValue)
      }
    }
  },
  methods: {
    ...mapMutations('flux-app', ['setConstat','setcptProduct', 'setcptReparation','setActiveTab']),
    ...mapMutations('cart-app', ['addProduct', 'getTotalCart']),
    onChangeTab(value){
        this.tabName = value
        this.setActiveTab(value)
    },
    editRepair(indexes) {
       this.$emit('edit', indexes)
    },
    evenement($value) {
        this.$emit('evenement', $value)
    },
    showModal() {
      this.$emit('showmodal', this.flux.level2)     
    },
    closeModal() {
      this.isModalVisible = false;
    }
  },
  components : {
    //Reparationsimple,
    //Empiecement,
    //Patch,
    //Coudiere,
    Modal,
    'reparation_simple_jean': () => import('./reparation_simple_jean.vue'),
    'empiecement': () => import('./empiecement.vue'),
    'patch': () => import('./patch.vue'),
    //'coudieres': () => import('./coudiere.vue')
    'genouillere': () => import('./genouillere.vue')
  }
}
</script>